import { Formik } from "formik";
import api from "../../../services/api";
import * as Yup from "yup";
import styled from "styled-components";
import { toast } from "react-toastify";
import Grid from "../../utils/Grid";
import Flex from "../../utils/Flex";
import LoadingSmall from "../../utils/LoadingSmall";
import Input from "../../utils/forms/Input";
import Button from "../../utils/forms/Button";
import DropFile from "../../utils/forms/DropFile";
import { useNavigate } from "react-router-dom";
import TextArea from "../../utils/forms/TextArea";
const AddForm = (props) => {
  let navigate = useNavigate();
  return (
    <Formik
      initialValues={{
        nameSpanish: "",
        nameEnglish: "",
        image: "",
        description: "",
      }}
      validationSchema={Yup.object({
        nameSpanish: Yup.string().required("Este campo es obligatorio"),
        nameEnglish: Yup.string().required("Este campo es obligatorio"),
        image: Yup.string().required("Este campo es obligatorio"),
        description: Yup.string().required("Este campo es obligatorio"),
      })}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        const formData = new FormData();
        formData.append("nameSpanish", values.nameSpanish);
        formData.append("nameEnglish", values.nameEnglish);
        formData.append("image", values.image);
        formData.append("description", values.description);
        api
          .post("/categories", formData, config)
          .then((res) => {
            console.log(res);
            setSubmitting(false);
            toast.success("Categoria creada correctamente");
            navigate(`/categories`);
          })
          .catch((err) => {
            console.log(err);
            setSubmitting(false);
            toast.error("Lo sentimos, tuvimos un error, intente nuevamente");
          });
      }}
    >
      {({
        handleSubmit,
        isSubmitting,
        validateForm,
        setFieldTouched,
        setFieldValue,
        values,
        errors,
      }) => (
        <>
          <MyForm onSubmit={handleSubmit}>
            <Grid columns="2">
              <Input
                name="nameSpanish"
                placeholder="Nombre de la categoría (Español)"
                type="text"
                label="Nombre de la categoría (Español)"
              />
              <Input
                name="nameEnglish"
                placeholder="Nombre de la categoría (Inglés)"
                type="text"
                label="Nombre de la categoría (Inglés)"
              />
            </Grid>
            <Grid columns="1">
              <TextArea
                name="description"
                placeholder="Descripción de la categoría"
                type="text"
                label="Descripción de la categoría"
              />
              <DropFile
                name="image"
                setFieldValue={setFieldValue}
                values={values}
              />
            </Grid>
            <Flex margin="30px 0 0 0" h="flex-end">
              <Button type="submit" disabled={isSubmitting}>
                {isSubmitting ? <LoadingSmall /> : "Añadir"}
              </Button>
            </Flex>
          </MyForm>
        </>
      )}
    </Formik>
  );
};
const MyForm = styled.form`
  padding: 1em 0 1em 0;
  width: 100%;
`;
export default AddForm;
