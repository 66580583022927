const createFormData = (values) => {
  const formData = new FormData();
  Object.entries(values).forEach(([key, value]) => {
    formData.append(key, value);
  });
  for (var value of formData.values()) {
    console.log(value);
  }
  return formData;
}

export default createFormData;