import styled from 'styled-components';
const EditImage = props => {
  const { values, setFieldValue, name } = props; 
  return (
    <Wrap>
      <Delete onClick={() => setFieldValue(name, '')}>
        <i className="material-icons-outlined">delete</i>
      </Delete>
      <img src={values[name]} alt={name} />
    </Wrap>
  )
}
const Wrap = styled.div`
  padding:2em 1em;
  border:1px solid ${props => props.theme.separatorColor};
  display: flex;
  justify-content:center;
  align-items: center;
  position:relative;
  img {
    display: block;
    width: auto;
    max-height: 200px;
  }
`
const Delete = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  background: ${props => props.theme.primaryColor};
  border: none;
  outline: none;
  cursor: pointer;
  color: white;

`
export default EditImage