import {useContext} from "react";
import styled from 'styled-components'
import Botonera from './Botonera';
import {GlobalsContext} from '../../context/globals';
const SideNavComponent = props => {
  const { state: { menuStatus } } = useContext(GlobalsContext);
  return (
    <Menu className={`${menuStatus ? 'open' : ''}`}>
      <Botonera/>
    </Menu>
  );
};
const Menu = styled.div`
  width: 120px;
  height:calc(100vh - 90px);
  border-radius: 0 .75rem .75rem 0;
  overflow-y: scroll;
  position: fixed;
  top:90px;
  left: -120px;
  z-index: 1029;
  transition: .2s all ease-in;
  background-color: ${props => props.theme.background};
  ${
    props => {
      if(props.theme.name === 'light'){
        return 'box-shadow: 0 3px 30px rgba(0,0,0,.1),0 3px 20px rgba(0,0,0,.1);'
      }else{
        return 'box-shadow: 0 3px 30px rgba(0,0,0,.3),0 3px 24px rgba(0,0,0,.3);'
      }
    }
  }

  &.open{
    left:0;
  }
`
//
export default SideNavComponent;
