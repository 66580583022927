import styled from "styled-components";
const ImageAvatar = (props) => {
  let hour = new Date();
  return (
    <Avatar>
      <img src={`${props.src}?ver=${hour}`} alt="Avatar" />
    </Avatar>
  );
};
const Avatar = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  margin: 10px 0;
  background-color: black;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
export default ImageAvatar;
