import api from "../services/api";
const UserService = {
  logOut: async () => {
    localStorage.clear();
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    delete api.defaults.headers.authorization;
    window.location.replace('/login');
    return true
  },
  isLogged: () => {
    const token = localStorage.getItem('token')
    if(token){
      api.defaults.headers.authorization = `Bearer ${token}`
      return true
    }else{
      return false
    }
  },
  setToken: (token) => {
    api.defaults.headers.authorization = `Bearer ${token}`;
    localStorage.setItem('token', token)
    return true
  },
  setUser: (user) => {
    localStorage.setItem('user', JSON.stringify(user))
    return true
  },
  hasRole: (roles) => {
    const userRole = UserService.getUser().role.name;
    if (!roles) {
      return true;
    }
    if (Array.isArray(roles)) {
      return roles.includes(userRole);
    }
    if (roles === userRole) {
      return true;
    }
    return false;
  },
  getUser() {
    let response = JSON.parse(localStorage.getItem('user'))
    return response
  },
  getToken() {
    let response = localStorage.getItem('token')
    let token = response ? response.token : null
    return token
  }
}
export default UserService;
