import MainTitle from '../utils/MainTitle';
import Content from '../utils/Content';
import Table from './Table';
const Providers = (props) => {
  return (
    <>
      <MainTitle>
        <h1>Proveedores</h1>
      </MainTitle>
      <Content>
        <Table />
      </Content>
    </>
  );
};

export default Providers;
