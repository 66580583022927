import React from "react";
import styled from "styled-components";
const Button = props => {
  return (
    <MyButton disabled={props.disabled} {...props}>{props.children}</MyButton>
  );
};
const MyButton = styled.button`
  line-height: 1.5;
  font-weight: 700;
  letter-spacing: .05rem;
  padding: .75rem 2.6rem .6rem 2.6rem;
  background-color: ${props=>props.theme.primaryColor};
  color: #FFF;
  border-radius: 50px;
  transition: all .2s ease-in;
  border:none;
  cursor: pointer;
  &:hover{
    color: #fff;
    background-color: ${props=>props.theme.primaryHoverColor};
  }
  &:disabled {
    opacity: 0.5;
    &:hover {
      transform: scale(1);
    }
  }
`;

export default Button;
