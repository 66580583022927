import React from 'react';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import { GlobalsProvider } from './context/globals';
import { createRoot } from 'react-dom/client';
import './css/globals.scss';
const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <GlobalsProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </GlobalsProvider>
);
