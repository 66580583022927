import React, {  useReducer, createContext } from 'react';

const initialState = {
  isDarkMode:false,
  menuStatus:true,
  socket:null,
  contain:"welcome",
  selectedRoom:'',
  roomData:null,
  user:{},
}

function reducer(state, action) {
    return { ...state, ...action };
}

const GlobalsContext = createContext();

const GlobalsProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    return (
      <GlobalsContext.Provider value={{ state, dispatch }}>
        {children}
      </GlobalsContext.Provider>
    );
};

export { GlobalsContext, GlobalsProvider };
