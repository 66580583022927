import { useState, useEffect } from 'react';
import moment from 'moment';
import api from '../services/api';
export const useDataTable = (url) => {
  const [isLoading, setIsLoading] = useState(true);
  const [response, setResponse] = useState([]);
  const [totalDocs, setTotalDocs] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [selectedAccount, setSelectedAccount] = useState('all');
  const [showHidden, setShowHidden] = useState(true);
  const fetchData = async (page) => {
    try {
      setIsLoading(true);
      let operator = selectedAccount === 'all' ? '' : selectedAccount;
      let urlParams = `/${url}?page=${page}&limit=${perPage}&search=${search}&account=${operator}&from=${date}&to=${date}&isAdmin=true`;
      let response = await api.get(urlParams);
      setResponse(response.docs);
      setTotalDocs(response.totalDocs);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };
  const handlePageChange = (page) => {
    setPage(page);
    fetchData(page);
  };
  const handlePerRowsChange = async (newPerPage, page) => {
    try {
      setIsLoading(true);
      let operator = selectedAccount === 'all' ? '' : selectedAccount;
      let urlParams = `/${url}?page=${page}&limit=${newPerPage}&search=${search}&account=${operator}&from=${date}&to=${date}`;
      let response = await api.get(urlParams);
      setResponse(response.data.docs);
      setPerPage(newPerPage);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    fetchData(page);
  }, [search, selectedAccount, date, showHidden]);
  return {
    isLoading,
    response,
    totalDocs,
    perPage,
    page,
    handlePageChange,
    handlePerRowsChange,
    setSearch,
    selectedAccount,
    setSelectedAccount,
    date,
    setDate,
    showHidden,
    setShowHidden,
  };
};
