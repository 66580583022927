import axios from 'axios';
import UserService from './UserService';
const api = axios.create({
  baseURL: `${process.env.REACT_APP_API}/`,
  credentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

api.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    console.log(error);
    const baseUrl = error.response.config.baseURL;
    const incomingUrl = error.response.config.url;
    const cut = incomingUrl.replace(baseUrl, '');
    if (error.response.status === 401 && cut !== 'signIn') {
      UserService.logOut();
      // .then(result => {
      //   localStorage.removeItem("user");
      //   window.location.replace('/login')
      // })
      // .catch(e => {
      //   console.log(e)
      //   window.location.replace('/login')
      // });
      console.log(error.response.status);
    } else {
      //TODO:
      return Promise.reject(error);
    }
  }
);

export default api;
