import React, {useContext} from 'react'
import styled from 'styled-components'
import Switch from "react-switch";
import {GlobalsContext} from '../../context/globals'
const SwitchBase = props => {
  const { state: {isDarkMode}, dispatch } = useContext(GlobalsContext);
  const handleChange = (status) => {
    localStorage.setItem('darkMode', status)
    dispatch({
      isDarkMode:status
    })
  }
  return(<>
    <Wrap>
      <Switch
        checked={isDarkMode}
        onChange={handleChange}
        checkedIcon={<i className="material-icons-outlined">light_mode</i>}
        uncheckedIcon={<i className="material-icons-outlined">dark_mode</i>}
        width={40}
        height={20}
        handleDiameter={13}
        activeBoxShadow={null}
      />
    </Wrap>
  </>)
}
const Wrap = styled.div`
  padding-top: 2px;
  i{
    font-size: 12px;
    transform:translate(5px,3px)
  }
  .react-switch-bg{
    ${
      props => {
        if(props.theme.name === 'light'){
          return `background-color: ${props.theme.separatorColor} !important`
        }else{
          return (
            `
            background-color: transparent !important;
            border: 1px solid ${props.theme.primaryColor} !important;
            `
          )
        }
      }
    }
  }
  .react-switch-handle{
    ${
      props => {
        if(props.theme.name === 'light'){
          return `background-color: ${props.theme.background} !important;`
        }else{
          return `background-color: ${props.theme.primaryColor} !important;`
        }
      }
    }
    outline: none!important;
  }
`
export default SwitchBase
