import { Routes, Route} from "react-router-dom";
import Layout from "./layout";
// pages
import Login from "./components/Login";
import Dashboard from "./components/dashboard";
import Users from "./components/users";
import Categories from "./components/categories";
import AddCategory from "./components/categories/add";
import EditCategory from "./components/categories/edit";
import Insurances from "./components/insurances";
import AddInsurance from "./components/insurances/add";
import EditInsurance from "./components/insurances/edit";
import Providers from "./components/providers";
import EditProvider from "./components/providers/edit";
const Router = () => {
  return(
    <Routes> 
      <Route path="/" element={<Layout />}>
        <Route index element={<Dashboard />} />
        <Route path="/users" element={<Users />} />
        <Route path="categories">
          <Route index element={<Categories />} />
          <Route path="add" element={<AddCategory />} />
          <Route path="edit/:slug" element={<EditCategory />} />
        </Route>
        <Route path="insurances">
          <Route index element={<Insurances />} />
          <Route path="add" element={<AddInsurance />} />
          <Route path="edit/:slug" element={<EditInsurance />} />
        </Route>
        <Route path="providers">
          <Route index element={<Providers />} />
          <Route path="edit/:id" element={<EditProvider />} />
        </Route>
      </Route>
      <Route path="/login" element={<Login />} />
    </Routes>
  )
}
export default Router;