import MainTitle from '../../utils/MainTitle'
import Content from '../../utils/Content'
import LinkButton from '../../utils/LinkButton'
import AddForm from './Form'
const AddInsurace = props => {
  return (<>
    <MainTitle>
      <h1>Añadir Seguro</h1>
      <LinkButton to="/insurances">Regresar</LinkButton>
    </MainTitle>
    <Content>
      <AddForm />
    </Content>
  </>)
}

export default AddInsurace