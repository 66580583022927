import MainTitle from '../utils/MainTitle'
import Content from '../utils/Content'
import LinkButton from '../utils/LinkButton'
import Table from './Table'
const Categories = props => {
  return (<>
    <MainTitle>
      <h1>Seguros</h1>
      <LinkButton to="/insurances/add">Añadir seguro</LinkButton>
    </MainTitle>
    <Content>
      <Table />
    </Content>
  </>)
}

export default Categories