import styled from 'styled-components';
import DataTable from 'react-data-table-component';
import Skeleton from '../utils/SkeletonTable';
const StyledTable = (props) => {
  const conditionalRowStyles = [
    {
      when: (row) => row.toggleSelected,
      style: {
        backgroundColor: '#f47304',
      },
    },
    {
      when: (row) => !row.toggleSelected,
      style: {
        backgroundColor: 'transparent',
      },
    },
    {
      when: (row) => row.statusCampaign === 'active' && row.stats && row.stats.calling === 0,
      style: {
        backgroundColor: 'rgba(255, 0, 0, 0.05)',
      },
    },
  ];
  return (
    <Wrap className={props.paginationPosition === 'bottom' ? 'bottom' : 'top'}>
      <MyTable
        className='celdas'
        {...props}
        p
        progressComponent={
          <div style={{ width: '100%' }}>
            <Skeleton count='15' />
          </div>
        }
        conditionalRowStyles={conditionalRowStyles}
        paginationComponentOptions={{
          rowsPerPageText: 'Por página:',
          rangeSeparatorText: 'de',
        }}
      />
    </Wrap>
  );
};

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme.fontColor}!important;
  .rdt_TableHeader {
    display: none !important;
  }
  .rdt_Pagination {
    background-color: transparent !important;
    color: ${(props) => props.theme.fontColor}!important;
    border: none !important;
  }
  #pagination-first-page,
  #pagination-previous-page,
  #pagination-next-page,
  #pagination-last-page {
    color: ${(props) => props.theme.fontColor};
    fill: ${(props) => props.theme.fontColor};
    &:disabled {
      opacity: 0.1;
    }
  }
  &.top {
    .rdt_TableHeader {
      display: none !important;
      order: 1;
    }
    .celdas {
      order: 2;
    }
  }
`;
const MyTable = styled(DataTable)`
  .rdt_Table {
    background-color: transparent;
    color: ${(props) => props.theme.fontColor}!important;
  }
  .rdt_TableHeadRow {
    background: transparent;
    color: ${(props) => props.theme.fontColor}!important;
    border-bottom-color: ${(props) => props.theme.separatorColor}!important;
  }
  .rdt_TableCol {
    color: ${(props) => props.theme.fontColor}!important;
  }
  .rdt_TableRow {
    /* background: transparent; */
    color: ${(props) => props.theme.fontColor}!important;
    border-bottom-color: ${(props) => props.theme.separatorColor}!important;
  }
  .rdt_TableCell {
    background: transparent;
    color: ${(props) => props.theme.fontColor}!important;
    padding: 1em;
  }
  .rdt_Table {
    div {
      color: ${(props) => props.theme.fontColor}!important;
    }
  }
  .rdt_Table + div {
    div {
      background: transparent;
      color: ${(props) => props.theme.fontColor}!important;
    }
  }
`;
export default StyledTable;
