import React from 'react'
import styled from 'styled-components'
const Content = (props) => {
  return <StyledContent {...props}>{props.children}</StyledContent>
}
const StyledContent = styled.section`
  width: 100%;
  background: ${props=>props.theme.background};
  border-radius: ${({borderRadius})=> borderRadius ? borderRadius : '.75rem'};
  padding: 1.75rem;
  margin-top:${({separator }) => separator ? separator : '0'};
  margin-bottom:${({ margin }) => margin ? margin : '0'};
  ${
    props => {
      if(props.theme.name === 'light'){
        return 'box-shadow: 0 1px 15px rgba(0,0,0,.04),0 1px 6px rgba(0,0,0,.04);'
      }else{
        return 'box-shadow: 0 1px 15px rgba(0,0,0,.1),0 1px 8px rgba(0,0,0,.1);'
      }
    }
  }

`
export default Content
