import React from "react";
import styled from "styled-components";
import LoginForm from "./LoginForm";

const Login = props => {
  return (
    <MainWrap>
      <Container>
        <SliderWrap />
        <DataWrap>
          <LoginWrap>
            <h1>Two Rivers</h1>
            <LoginForm />
          </LoginWrap>
        </DataWrap>
      </Container>
    </MainWrap>
  );
};
const MainWrap = styled.main`
  height: 100vh;
`;
const Container = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #FFF;
  overflow: hidden;
  box-shadow: 0 1px 15px rgba(0,0,0,.04),0 1px 6px rgba(0,0,0,.04);
  display: flex;
  justify-content: space-between;
`
const DataWrap = styled.section`
  width: 55%;
  display: flex;
  justify-content: center;
  align-items: center;
`
const LoginWrap = styled.div`
  width: 80%;
  h1{
    font-size: 2rem;
    font-weight: bold ;
  }
  img{
    display: block;
    width: 100%;
    max-width: 150px;
  }
  .welcome{
    font-weight: 300;
    margin-top: 1em;
  }
`
const SliderWrap = styled.section`
  width: 45%;
  height:100%;
  position: relative;
  background: url(/img/bannerLogin.jpg) no-repeat center center;
  background-size: cover;
`
export default Login;
