import {Formik} from 'formik';
import api from '../../../services/api';
import * as Yup from 'yup';
import styled from 'styled-components'
import {toast} from 'react-toastify'
import Grid from '../../utils/Grid'
import Flex from '../../utils/Flex'
import LoadingSmall from '../../utils/LoadingSmall'
import Input from '../../utils/forms/Input'
import Button from '../../utils/forms/Button'
import DropFile from '../../utils/forms/DropFile'
import { useNavigate } from "react-router-dom";
const AddForm = props => {
  let navigate = useNavigate();
  return (
    <Formik
      initialValues={{
        name: '',
        price: '',
        description: '',
      }}
      validationSchema={Yup.object({
        name: Yup.string()
          .required('Este campo es obligatorio'),
        price: Yup.string()
          .required('Este campo es obligatorio'),
        description: Yup.string()
          .required('Este campo es obligatorio'),
      })}
      onSubmit={(values, {setSubmitting}) => {
        setSubmitting(true);
        values.price = Number(values.price)
        api.post('/insurance', values) 
        .then(res => {
          console.log(res)
          setSubmitting(false);
          toast.success('Seguro creado correctamente')
          navigate(`/insurances`);
        })
        .catch(err => {
          console.log(err)
          setSubmitting(false);
          toast.error('Lo sentimos, tuvimos un error, intente nuevamente')
        })
      }}
      >
      {({handleSubmit,isSubmitting,validateForm,setFieldTouched,setFieldValue,values,errors}) => (<>
        <MyForm onSubmit={handleSubmit}>
          <Grid columns="1">
            <Input
              name="name"
              placeholder="Nombre del seguro"
              type="text"
              label="Nombre del seguro"
            />
            <Input
              name="price"
              placeholder="Precio MXN"
              type="text"
              label="Precio MXN"
            />
            <Input
              name="description"
              placeholder="Describe este seguro"
              type="text"
              label="Descripción"
            />
          </Grid>
          <Flex margin="30px 0 0 0" h="flex-end">
            <Button type="submit" disabled={isSubmitting}>
              {isSubmitting ? <LoadingSmall /> : 'Añadir'}
            </Button>
          </Flex>
        </MyForm>
      </>)}
    </Formik>
  )
}
const MyForm = styled.form`
  padding: 1em 0 1em 0;
	width:100%;
`
export default AddForm