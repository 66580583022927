import {
  Menu,
} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';

const MyMenu = props => {
  const { children, icon } = props;
  return(
    <Menu 
      menuButton={<i className="material-icons-outlined">keyboard_arrow_down</i>} 
      transition
      direction="left"
      align="start"
      position="auto"
      arrow={true}>
        { children }
    </Menu>
  )
}
export default MyMenu;