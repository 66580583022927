import { Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import Grid from '../../utils/Grid';
import Input from '../../utils/forms/Input';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import TextArea from '../../utils/forms/TextArea';
import Flex from '../../utils/Flex';
import Button from '../../utils/forms/Button';
import createFormData from '../../utils/forms/createFormData';
import api from '../../../services/api';
import { toast } from 'react-toastify';
import LoadingSmall from '../../utils/LoadingSmall';
const AddForm = (props) => {
  let navigate = useNavigate();
  const { data, dataActivities } = props;
  return (
    <Formik
      initialValues={{
        firstName: data.firstName,
        paternalSurname: data.paternalSurname,
        email: data.email,
        rfc: data.provider.rfc,
        rnt: data.provider.rnt,
        interests: data.provider.interests,
        experience: data.provider.experience,
        courses: data.provider.courses,
        phone: data.phone,
        dateOfBirth: moment(data.dateOfBirth).format('YYYY-MM-DD'),
        country: data.country,
        city: data.city,
      }}
      validationSchema={Yup.object({
        firstName: Yup.string().required('Este campo es obligatorio'),
        paternalSurname: Yup.string().required('Este campo es obligatorio'),
        dateOfBirth: Yup.date()
          .required('Este campo es obligatorio')
          .max(
            new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDate()),
            'Debes de ser mayor de edad'
          ),
        city: Yup.string().required('Este campo es obligatorio'),
        email: Yup.string()
          .email('Debe ser un correo válido')
          .required('Este campo es obligatorio'),
        phone: Yup.string()
          .matches(/^[0-9]+$/, 'Sólo números son permitidos sin espacios')
          .max(10, 'Máximo 10 números')
          .required('Este campo es obligatorio'),
        courses: Yup.string()
          .required('Este campo es obligatorio')
          .min(25, 'Este campo debe tener mímino 25 caracteres'),
        experience: Yup.string()
          .required('Este campo es obligatorio')
          .min(50, 'Este campo debe tener mímino 50 caracteres'),
        interests: Yup.string()
          .required('Este campo es obligatorio')
          .min(100, 'Este campo debe tener mímino 100 caracteres'),
        rfc: Yup.string()
          .matches(/^[A-Za-zñÑ&]{3,4}\d{6}\w{3}$/, 'No es un formato válido')
          .required('Este campo es obligatorio'),
        rnt: Yup.string(),
      })}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        api
          .patch(`/providers/${data._id}/`, values)
          .then((res) => {
            toast.success('Actualización exitosa');
            setSubmitting(false);
          })
          .catch((err) => {
            console.log(err, 'error');
          });
      }}
    >
      {(formik) => (
        <>
          <Grid columns='2'>
            <ActivityData color='green'>
              <h1>Actividades Activas</h1>
              <p>{dataActivities.activeActivities}</p>
            </ActivityData>
            <ActivityData color='red'>
              <h1>Actividades Inactivas</h1>
              <p>{dataActivities.inactiveActivities}</p>
            </ActivityData>
          </Grid>
          <MyForm props={formik} onSubmit={formik.handleSubmit}>
            <Grid columns='2'>
              <Input name='firstName' type='text' label='Nombre(s)' />
              <Input name='paternalSurname' type='text' label='Apellidos(s)' />
            </Grid>
            <Grid columns='2'>
              <Input name='email' type='text' label='Email' />
              <Input name='rfc' type='text' label='RFC' />
            </Grid>
            <Grid columns='2'>
              <Input name='phone' type='text' label='Telefono' />
              <Input name='dateOfBirth' type='date' label='Fecha de Nacimiento' />
            </Grid>
            <Grid columns='2'>
              <Input name='rnt' type='text' label='Registro Nacional de Turismo (RNT)' />
              <Input name='city' type='text' label='Ciudad' />
            </Grid>
            <Grid columns='1'>
              <TextArea name='interests' type='text' label='Intereses' />
              <TextArea name='experience' type='text' label='Experiencia' />
              <TextArea name='courses' type='text' label='Cursos' />
            </Grid>
            <Flex margin='30px 0 0 0' h='flex-end'>
              <Button type='submit' disabled={formik.isSubmitting}>
                {formik.isSubmitting ? <LoadingSmall /> : 'Actulizar'}
              </Button>
            </Flex>
          </MyForm>
        </>
      )}
    </Formik>
  );
};
const MyForm = styled.form`
  padding: 1em 0 1em 0;
  width: 100%;
`;

const ActivityData = styled.div`
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${(props) => props.color};
  border-radius: 30px;
  color: #fff;
  h1 {
    font-size: 20px;
    font-weight: bold;
  }
  p {
    font-size: 20px;
  }
`;
export default AddForm;
