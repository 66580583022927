import { Menu, MenuItem } from "@szhsin/react-menu";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
const MenuTable = (props) => {
  let { pathname } = useLocation();
  let navigation = useNavigate();
  const { id, edit } = props;
  const goToEdit = () => {
    navigation(`${pathname}/edit/${id}`);
  };
  return (
    <Wrap>
      <Menu
        menuButton={<i className="material-icons-outlined">more_vert</i>}
        transition
        direction="left"
        align="start"
        position="auto"
        arrow={true}
      >
        {edit && <MenuItem onClick={goToEdit}>Ver más</MenuItem>}
      </Menu>
    </Wrap>
  );
};
const Wrap = styled.div`
  margin-right: 12px;
  cursor: pointer;
`;
export default MenuTable;
