import { useContext, useEffect } from "react";
import { GlobalsContext } from "./context/globals";
import Router from "./Router";
import { ThemeProvider } from 'styled-components';
import { LightTheme, DarkTheme } from './css/colors-styled';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
const App = () => {
  const { state: { isDarkMode }, dispatch } = useContext(GlobalsContext);
  useEffect(()=>{
    if(localStorage.getItem('darkMode')){
      const mode =JSON.parse(localStorage.getItem('darkMode'))
      dispatch({
        isDarkMode:mode
      })
    }
  },[dispatch])
  return (
    <ThemeProvider theme={isDarkMode ? DarkTheme : LightTheme}>
      <ToastContainer
        position="top-left"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Router/>
    </ThemeProvider>
  );
}
export default App;
