import React from 'react'
import styled from 'styled-components'
import {NavLink} from 'react-router-dom'
import UserService from "../../services/UserService";
const Botonera = props =>{
  const user = UserService.getUser();

  return (
    <Wrap>
      <li>
        <NavLink
          to="/"
          activeclassname="active">
          <i className="material-icons-outlined">dashboard</i>
          Dashboard
        </NavLink>
      </li>
      <li>
        <NavLink
          to="/providers"
          activeclassname="active">
          <i className="material-icons-outlined">person</i>
          Proveedores
        </NavLink>
      </li>
      <li>
        <NavLink
          to="/categories"
          activeclassname="active">
          <i className="material-icons-outlined">category</i>
          Categorías
        </NavLink>
      </li>
      <li>
        <NavLink
          to="/insurances"
          activeclassname="active">
          <i className="material-icons-outlined">medical_information</i>
          Seguros
        </NavLink>
      </li>
    </Wrap>
  )
}
const Wrap = styled.ul`
  padding: 1em 0;
  li{
    list-style: none;
    display: block;
    position: relative;
    a{
      height: 110px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      border-bottom: 1px solid ${props=>props.theme.menuLines};
      color: ${props=>props.theme.fontColor};
      transition: all .2s ease-in;
      i{
        font-size: 32px;
        line-height: 42px;
      }
      &.active{
        color: ${props=>props.theme.primaryColor};
      }
      &.active::after {
        content: " ";
        background: ${props=>props.theme.primaryColor};
        color: #fff;
        border-radius: 10px;
        position: absolute;
        width: 6px;
        height: 90px;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
      }
    }

    a:hover{
      color: ${props=>props.theme.primaryColor};
    }
  }
  li:nth-child(1) a{
    border-top: 1px solid ${props=>props.theme.menuLines};
  }

`
export default Botonera
