import {useState} from 'react'
import Switch from "react-switch";
import styled from 'styled-components'
import api from '../../../services/api'
import {toast} from 'react-toastify'
const Desactivar = ({valor,id,url}) => {
  const [value,setValue] = useState(valor);
  const handleChange = (status) => {
    api.patch(`${url}${id}`,{status:status})
    .then(res=>{
      toast.success(`Actualizado correctamente`)
    })
    .catch(err=>{
      toast.error('Lo sentimos, tuvimos un error, intente nuevamente')
    })
    setValue(status)
  }
  return(
    <Wrap>
      <Switch
        onChange={handleChange}
        checked={value}
        width={40}
        height={20}
        handleDiameter={13}
        activeBoxShadow={null}
      />
    </Wrap>
  )
}
const Wrap = styled.div`
  width: 100%;
  .react-switch-bg{
    path{
      fill:${props=>props.theme.fontColor}
    }
    ${
      props => {
        if(props.theme.name === 'light'){
          return `background-color: ${props.theme.separatorColor} !important`
        }else{
          return (
            `
            background-color: transparent !important;
            border: 1px solid ${props.theme.primaryColor} !important;
            `
          )
        }
      }
    }
  }
  .react-switch-handle{
    ${
      props => {
        if(props.theme.name === 'light'){
          return `background-color: ${props.theme.background} !important;`
        }else{
          return `background-color: ${props.theme.primaryColor} !important;`
        }
      }
    }
    outline: none!important;
  }
`
export default Desactivar
