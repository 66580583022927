import React, {useContext} from 'react'
import styled from 'styled-components'
import {GlobalsContext} from '../../context/globals';
const Hamburguer = () => {
  const { state: { menuStatus }, dispatch } = useContext(GlobalsContext);
  const openNav = () =>{
    dispatch({menuStatus: !menuStatus})
  }
  return(
    <Wrap onClick={openNav}>
      <i className="material-icons-outlined">menu</i>
    </Wrap>
  )
}
const Wrap = styled.div`
  cursor: pointer;
  i{
    color:${props=>props.theme.fontColor};
  }
`
export default Hamburguer;
