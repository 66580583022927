import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import api from '../../services/api';
import Input from '../utils/forms/Input';
import Button from '../utils/forms/Button';
import LoadingSmall from '../utils/LoadingSmall';
import Grid from '../utils/Grid';
import { toast } from 'react-toastify';
import UserService from '../../services/UserService';
const LoginForm = () => {
  let navigate = useNavigate();
  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      validationSchema={Yup.object({
        email: Yup.string().required('Este campo es obligatorio'),
        password: Yup.string()
          .required('Este campo es obligatorio')
          .min(6, 'Contraseña debe contener al menos 6 caracteres'),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        try {
          const token = await api.post('/auth/login/admin', values);
          UserService.setToken(token.token);
          const user = await api.get('/auth/current-user');
          UserService.setUser(user);
          setSubmitting(false);
          navigate('/');
        } catch (e) {
          toast.error('Lo sentimos, tuvimos un error, intente nuevamente');
          setSubmitting(false);
        }
      }}
    >
      {(formik) => (
        <MyForm onSubmit={formik.handleSubmit}>
          <Grid columns='1'>
            <Input name='email' placeholder='Usuario' type='text' />
          </Grid>
          <Grid columns='1'>
            <Input name='password' placeholder='Contraseña' type='password' />
          </Grid>
          <Nav>
            <Button type='submit' disabled={formik.isSubmitting}>
              {formik.isSubmitting ? <LoadingSmall /> : 'Iniciar sesión'}
            </Button>
          </Nav>
        </MyForm>
      )}
    </Formik>
  );
};

const MyForm = styled.form`
  margin-top: 2rem;
  width: 100%;
`;
const Nav = styled.div`
  width: 100%;
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  a {
    color: inherit;
  }
`;
export default LoginForm;
