import { useState, useEffect } from "react";
import api from "../../../services/api";
import Skeleton from "../../utils/Skeleton";
import MainTitle from "../../utils/MainTitle";
import Content from "../../utils/Content";
import LinkButton from "../../utils/LinkButton";
import Form from "./Form";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
const Categories = (props) => {
  const { slug } = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  useEffect(() => {
    api
      .get(`/categories/${slug}`)
      .then((res) => {
        setData(res);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Lo sentimos, tuvimos un error, intente nuevamente");
      });
  }, []);
  return (
    <>
      <MainTitle>
        <h1>Editar Categoría</h1>
        <LinkButton to="/categories">Regresar</LinkButton>
      </MainTitle>
      <Content>
        {loading && <Skeleton count={16} />}
        {!loading && <Form data={data} />}
      </Content>
    </>
  );
};

export default Categories;
