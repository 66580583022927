const getChangedValues = (values, initialValues) => {
  return Object
  .entries(values)
  .reduce((acc, [key, value]) => {
    const hasChanged = initialValues[key] !== value
    if (hasChanged) {
      acc[key] = value
    }
    return acc
  }, {})
}

export default getChangedValues;