import styled from 'styled-components'
import Hamburguer from './Hamburguer'
import ToggleTheme from './ToggleTheme'
import FullScreenButton from './FullScreenButton'
import AvatarMenu from './AvatarMenu'
const Header = props => {
  return (
    <HeaderWrap>
      <LeftContent>
        <Hamburguer/>
      </LeftContent>
      <CenterContent>
        <h2>Two Rivers</h2>
      </CenterContent>
      <RightContent>
        <ToggleTheme />
        <FullScreenButton/>
        <AvatarMenu/>
      </RightContent>
    </HeaderWrap>
  );
};
const HeaderWrap = styled.header`
  width: 100%;
  height: 90px;
  padding: 0 3.5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 1030;
  background-color: ${props => props.theme.background};
  color: ${props => props.theme.fontColor};
  ${
    props => {
      if(props.theme.name === 'light'){
        return 'box-shadow: 0 1px 15px rgba(0,0,0,.04),0 1px 6px rgba(0,0,0,.04);'
      }else{
        return 'box-shadow: 0 1px 15px rgba(0,0,0,.1),0 1px 8px rgba(0,0,0,.1);'
      }
    }
  }
`
const LeftContent = styled.div`
  width: 45%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
const RightContent = styled.div`
  width: 45%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
const CenterContent = styled.div`
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  img{
    display: block;
    width: auto;
    max-height: 80px;
    ${
    props => {
      if(props.theme.name !== 'light'){
        return 'filter: grayscale(100%);'
      }
    }
  }

  }
`

export default Header;
