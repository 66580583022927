import { useEffect, useState, useContext } from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import Header from './header';
import UserService from '../services/UserService';
import SideNav from './sidenav';
import { GlobalsContext } from '../context/globals';
const Layout = () => {
  const [loading, setLoading] = useState(true);
  const {
    state: { menuStatus },
  } = useContext(GlobalsContext);
  useEffect(() => {
    if (UserService.isLogged()) {
      setLoading(false);
    } else {
      UserService.logOut();
    }
  }, []);
  return (
    <>
      {!loading && (
        <>
          <Header />
          <SideNav />
          <Main className={`${menuStatus ? 'open' : ''}`}>
            <Outlet />
          </Main>
        </>
      )}
    </>
  );
};
export default Layout;

const Main = styled.main`
  background-color: ${(props) => props.theme.body};
  color: ${(props) => props.theme.fontColor};
  padding-top: 100px;
  min-height: calc(100vh);
  padding: 120px 5% 50px 5%;
  transition: 0.2s all ease-in;
  &.open {
    padding-left: calc(5% + 120px);
  }
`;
