import { Formik } from "formik";
import api from "../../../services/api";
import * as Yup from "yup";
import styled from "styled-components";
import { toast } from "react-toastify";
import Grid from "../../utils/Grid";
import Flex from "../../utils/Flex";
import LoadingSmall from "../../utils/LoadingSmall";
import Input from "../../utils/forms/Input";
import Button from "../../utils/forms/Button";
import DropFile from "../../utils/forms/DropFile";
import { useNavigate } from "react-router-dom";
import EditImage from "../../utils/forms/EditImage";
import getChangedValues from "../../utils/forms/getChangedValues";
import createFormData from "../../utils/forms/createFormData";
const AddForm = (props) => {
  let navigate = useNavigate();
  const { data } = props;
  return (
    <Formik
      initialValues={{
        nameSpanish: data.name["es"],
        nameEnglish: data.name["en"],
        image: data.image,
        description: data.description.es,
      }}
      validationSchema={Yup.object({
        nameSpanish: Yup.string().required("Este campo es obligatorio"),
        nameEnglish: Yup.string().required("Este campo es obligatorio"),
        image: Yup.string().required("Este campo es obligatorio"),
        description: Yup.string().required("Este campo es obligatorio"),
      })}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        let realValues = getChangedValues(values, data);
        let finalData = createFormData(realValues);
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        api
          .patch(`/categories/${data.slug}`, finalData, config)
          .then((res) => {
            console.log(res, "response");
            setSubmitting(false);
            toast.success("Categoria creada correctamente");
            navigate(`/categories`);
          })
          .catch((err) => {
            console.log(err);
            setSubmitting(false);
            toast.error("Lo sentimos, tuvimos un error, intente nuevamente");
          });
      }}
    >
      {(formik) => (
        <>
          <MyForm props={formik} onSubmit={formik.handleSubmit}>
            <Grid columns="2">
              <Input
                name="nameSpanish"
                placeholder="Nombre de la categoría (Español)"
                type="text"
                label="Nombre de la categoría (Español)"
              />
              <Input
                name="nameEnglish"
                placeholder="Nombre de la categoría (Inglés)"
                type="text"
                label="Nombre de la categoría (Inglés)"
              />
              <Input
                name="description"
                placeholder="Descripción de la categoría"
                type="text"
                label="Descripción de la categoría"
              />
            </Grid>
            {formik.values.image !== data.image && (
              <Grid columns="1">
                <DropFile
                  name="image"
                  setFieldValue={formik.setFieldValue}
                  values={formik.values}
                />
              </Grid>
            )}
            {formik.values.image === data.image && (
              <Grid columns="2">
                <EditImage
                  name="image"
                  setFieldValue={formik.setFieldValue}
                  values={formik.values}
                />
              </Grid>
            )}
            {formik.dirty && (
              <Flex margin="30px 0 0 0" h="flex-end">
                <Button type="submit" disabled={formik.isSubmitting}>
                  {formik.isSubmitting ? <LoadingSmall /> : "Actualizar"}
                </Button>
              </Flex>
            )}
          </MyForm>
        </>
      )}
    </Formik>
  );
};
const MyForm = styled.form`
  padding: 1em 0 1em 0;
  width: 100%;
`;
export default AddForm;
