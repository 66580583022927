import {useCallback } from 'react'
import styled from 'styled-components'
import {useDropzone} from 'react-dropzone'
import { Field } from 'formik';
const MyDropzone = props => {
  const {setFieldValue, name} = props ;
  const onDrop = useCallback(acceptedFiles => {
    setFieldValue(name,acceptedFiles[0])
  }, [])
  const {getRootProps, getInputProps, isDragAccept} = useDropzone({onDrop})
  return (
    <Field name={name}>
      {({
          field,
          form,
          meta,
      }) => (<>
        <Wrap {...getRootProps()} className={isDragAccept ? 'drag' : '' }>
          <input
            {...getInputProps()}
            id='file'
            name="audioFile"
          />
          {field.value === '' &&
            <p>Da click o arrastra el archivo aquí</p>
          }
          {field.value.name !== '' &&
            <p>{field.value.name}</p>
          }
          {meta.touched && meta.error && (
            <MyError>
                {meta.error}
            </MyError>
          )}
        </Wrap>
      </>)}
    </Field>
  )
}
const Wrap = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3em 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => props.theme.separatorColor};
  border-style: dashed;
  color: ${props => props.theme.fontColor};
  cursor: pointer;
  outline: none;
  position: relative;
  transition: border .24s ease-in-out;
  position: relative;
  &.drag{
    border-color:${props => props.theme.primaryColor};
  }
`
const MyError = styled.div`
  width: 100%;
  color:red;
  padding:.3em;
  position:absolute;
  top:103%;
  font-size:12px;
  z-index: 9996;
  text-align: left;
`
const InfoDocument = styled.div`
  width: 100%;
  padding:.3em;
  margin-top: -37px;
  margin-bottom: 20px;
  font-size:12px;
  z-index: 9996;
  text-align: left;
  color:black;
`
const Loading = styled.div`
  position: absolute;
  left: 0;
  top: 0%;
  width: 100%;
  height: 100%;
  z-index: 9996;
  background-color: rgba(0,0,0,.8);
  display: flex;
  justify-content: center;
  align-items: center;
`
export default MyDropzone
