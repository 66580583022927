import React from 'react'
import styled from 'styled-components'
const FullScreenButtom = () => {
  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  }
  return(
    <Wrap onClick={toggleFullScreen}>
      <i className="material-icons-outlined">fullscreen</i>
    </Wrap>
  )
}
const Wrap = styled.div`
  cursor: pointer;
  margin: 0 1em;
  i{
    color:${props=>props.theme.contentFontColor};
  }
`
export default FullScreenButtom
