import MainTitle from '../utils/MainTitle'
import Content from '../utils/Content'
import LinkButton from '../utils/LinkButton'
import Table from './Table'
const Categories = props => {
  return (<>
    <MainTitle>
      <h1>Categorias</h1>
      <LinkButton to="/categories/add">Añadir categoría</LinkButton>
    </MainTitle>
    <Content>
      <Table />
    </Content>
  </>)
}

export default Categories