import { useEffect, useState } from 'react';
import api from '../../services/api';

import StyledTable from '../utils/StyledTable';
import ImageAvatar from '../utils/ImageAvatar';
import Deactivate from '../utils/forms/Deactivate';
import MenuTable from '../utils/MenuTable';
import { useDataTable } from '../../hooks/useDataTable';
const Table = (props) => {
  const {
    isLoading,
    response,
    totalDocs,
    perPage,
    handlePageChange,
    handlePerRowsChange,
    setSearch,
  } = useDataTable('insurance');

  const columns = [
    {
      name: 'Imagen',
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: 'Descripción',
      selector: (row) => row.description,
      sortable: true,
    },
    {
      name: 'Precio MX',
      selector: (row) => `$ ${row.price.mxn}`,
      sortable: true,
    },
    {
      name: 'Precio USD',
      selector: (row) => `$ ${row.price.usd}`,
      sortable: true,
    },
    {
      name: 'Precio EUR',
      selector: (row) => `$ ${row.price.eur}`,
      sortable: true,
    },
    {
      name: 'Estado',
      cell: (row) => {
        return (
          <Deactivate
            id={row._id}
            name={row.name}
            valor={row.default}
            url='/insurance/status-change/'
          />
        );
      },
      maxWidth: '100px',
      grow: 1,
    },
    {
      name: 'Opciones',
      cell: (row) => {
        return <MenuTable id={row._id} edit={true} />;
      },
      right: true,
      maxWidth: '100px',
      grow: 1,
    },
  ];

  return (
    <StyledTable
      data={response}
      columns={columns}
      progressPending={isLoading}
      pagination
      paginationServer
      paginationTotalRows={totalDocs}
      onChangeRowsPerPage={handlePerRowsChange}
      onChangePage={handlePageChange}
      noDataComponent={<div>No hay datos</div>}
      paginationPerPage={perPage}
      toggleHidden={false}
    />
  );
};
export default Table;
