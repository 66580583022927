import React from "react";
import { Field } from "formik";
import styled from "styled-components";
const TextArea = ({
  name,
  placeholder,
  type,
  label,
  disabled,
  maxlength,
  counter,
  quitError,
  withHelper,
  textHelper,
}) => {
  return (
    <>
      <Field name={name}>
        {({ field, form: { touched, errors }, meta }) => (
          <InputWrap>
            {label && <label>{label}</label>}
            <MyStyledInput
              type={type}
              placeholder={placeholder}
              className={meta.touched && meta.error ? "error" : ""}
              disabled={disabled}
              maxLength={maxlength}
              {...field}
            />
            {counter ? (
              <CounterContainer>
                {field.value.length} / {maxlength}
              </CounterContainer>
            ) : (
              ""
            )}
            {meta.touched && meta.error && !quitError && (
              <MyError>{meta.error}</MyError>
            )}{" "}
          </InputWrap>
        )}
      </Field>
    </>
  );
};
const InputWrap = styled.div`
  width: 100%;
  position: relative;
  label {
    margin-bottom: 0.5em;
    display: block;
    padding-left: 5px;
  }
`;
const CounterContainer = styled.p`
  position: absolute;
  right: 11px;
  bottom: -10px;
  background-color: #fff;
  color: #1ad376;
  padding: 3px;
  font-size: 12px;
`;
const MyStyledInput = styled.textarea`
  padding: 1em 0.8em;
  width: 100%;
  display: block;
  background: none;
  border: 1px solid ${(props) => props.theme.separatorColor};
  border-radius: 5px;
  outline: none;
  resize: none;
  min-height: 100px;
  color: ${(props) => props.theme.fontColor};
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  &.error {
    border: 1px solid red;
  }
  &:-webkit-autofill {
    appearance: inherit;
    background-color: inherit !important;
    background-image: none !important;
    color: inherit !important;
  }
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #000;
    opacity: 0.7; /* Firefox */
  }
`;
const MyError = styled.div`
  color: red;
  padding: 0.3em;
  position: absolute;
  top: 100%;
  transform: translateY(-20%);
  font-size: 10px;
  z-index: 9996;
`;

export default TextArea;
