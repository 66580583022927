import { useState, useEffect } from 'react';
import api from '../../../services/api';
import Skeleton from '../../utils/Skeleton';
import MainTitle from '../../utils/MainTitle';
import Content from '../../utils/Content';
import LinkButton from '../../utils/LinkButton';
import Form from './Form';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
const Providers = (props) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [dataActivities, setDataActivities] = useState(null);
  const [data, setData] = useState({});
  useEffect(() => {
    api
      .get(`/activity/reports/valid-activities-by-provider/${id}`)
      .then((res) => {
        setDataActivities(res);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Lo sentimos, tuvimos un error al obtener algunos datos');
      });
    api
      .get(`/providers/${id}`)
      .then((res) => {
        setData(res);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Lo sentimos, tuvimos un error, intente nuevamente');
      });
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);
  return (
    <>
      <MainTitle>
        <h1>Detalle</h1>
        <LinkButton to='/providers'>Regresar</LinkButton>
      </MainTitle>
      <Content>
        {loading && <Skeleton count={16} />}
        {!loading && <Form data={data} dataActivities={dataActivities} />}
      </Content>
    </>
  );
};

export default Providers;
