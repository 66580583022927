import styled from 'styled-components'
import {Link} from 'react-router-dom'
const LinkButton = ({to,children}) => {
  return(
    <Wrap to={to}>{children}</Wrap>
  )
}
const Wrap = styled(Link)`
  line-height: 1.5;
  font-weight: 700;
  letter-spacing: .05rem;
  padding: .75rem 2.6rem .6rem 2.6rem;
  background-color: ${props=>props.theme.primaryColor};
  color: #FFF;
  border-radius: 50px;
  transition: all .2s ease-in;
  &:hover{
    color: #fff;
    background-color: ${props=>props.theme.primaryHoverColor};
  }
`
export default LinkButton;
