import MainTitle from '../utils/MainTitle'
import Content from '../utils/Content'
const Users = () => {
  return(<>
    <MainTitle>
      <h1>Proveedores</h1>
    </MainTitle>
    <Content>

    </Content>
  </>)
}
export default Users;