import React from "react";
import styled from "styled-components";
import api from "../../services/api";

const ButtonCsv = () => {
  const handleClick = () => {
    api.get("/reports/bookings/download-csv-report").then((res) => {
      const url = window.URL.createObjectURL(new Blob([res]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Reporte.csv");
      document.body.appendChild(link);
      link.click();
    });
  };

  return (
    <Wrap onClick={handleClick}>
      <i className="material-icons-outlined">download</i>
    </Wrap>
  );
};

const Wrap = styled.div`
  position: fixed;
  padding: 14px;
  width: 50px;
  height: 50px;
  bottom: 30px;
  right: 40px;
  color: #000;
  text-align: center;
  border-radius: 50%;
  z-index: 100;
  text-decoration: none;
  background-color: #1ad376;
  cursor: pointer;
`;

export default ButtonCsv;
