import React from 'react'
import styled from 'styled-components'
import { withTheme } from 'styled-components';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
const RecetaSkeleton = ({ theme, count }) => {
  return (
    <SkeletonTheme color={theme.body} highlightColor={theme.background}>
      <Wrap>
        <Skeleton count={count} />
      </Wrap>
    </SkeletonTheme>
  )
}
const Wrap = styled.div`
  width: 100%;
  .react-loading-skeleton{
    margin-bottom: .5rem;
  }
`
export default withTheme(RecetaSkeleton)
