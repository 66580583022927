import styled from 'styled-components'
import UserService from '../../services/UserService'
import Avatar from 'react-avatar';
import Menu from '../../components/utils/Menu'
import { MenuItem } from '@szhsin/react-menu';
const AvatarMenu = () => {
  const user = UserService.getUser()
  

  return(<>
      <Wrap>
        <h2>{user.firstName}</h2>
        <Avatar
          size="40" 
          round={true}
          color="#F8F8F8"
          fgColor="#4B566B"
          name={user.firstName} 
        />
        <Menu>
          <MenuItem onClick={()=>UserService.logOut()}>Cerrar sesión</MenuItem>
        </Menu>
      </Wrap>

  </>)
}
const Wrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  h2{
    margin-bottom: 0;
    font-weight: 400;
    font-size: .8rem;
    color:${props=>props.theme.contentFontColor};
    margin-right:1em;
  }

`


export default AvatarMenu
