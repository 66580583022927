import React from "react";
import { Field } from "formik";
import styled from "styled-components";
const Input = ({ name, placeholder, type, label, disabled, maxlength, quitError,withHelper,textHelper }) => {
  return (
    <>
      <Field name={name}>
        {({ field, form: { touched, errors }, meta }) => (
          <InputWrap>
            {label &&
              <Label>
                {label}
              </Label>
            }
            <MyStyledInput
              type={type}
              placeholder={placeholder}
              className={meta.touched && meta.error ? "error" : ""}
              disabled={disabled}
              maxLength={maxlength}
              {...field}
            />
            {meta.touched && meta.error && !quitError && <MyError>{meta.error}</MyError>}
          </InputWrap>
        )}
      </Field>
    </>
  );
};
const InputWrap = styled.div`
  width: 100%;
  margin-bottom: 1em;
  position: relative;

  label {
    margin-bottom: 0.5em;
    display: block;
    padding-left: 5px;
  }
`;
const MyStyledInput = styled.input`
  padding: 1em 0.8em;
  width: 100%;
  display: block;
  margin-bottom: 1em;
  background: none;
  border:1px solid ${props => props.theme.separatorColor};
  border-radius: 5px;
  outline: none;
  color: ${props => props.theme.fontColor};
  &.error {
    border: 1px solid red;
  }
  &:-webkit-autofill {
    appearance: inherit;
    background-color: inherit !important;
    background-image: none !important;
    color: inherit !important;
  }
  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${props => props.theme.separatorColor};
    opacity: 1; /* Firefox */
  }
`;
const MyError = styled.div`
  color: red;
  padding: 0.3em;
  position: absolute;
  top: 90%;
  transform: translateY(-20%);
  font-size: 10px;
  z-index: 9996;
`;
const Label = styled.label`
  display: flex!important;
  align-items: center;
`
export default Input;
